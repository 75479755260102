$color-green: rgb(129, 142, 83);
$color-green-hover: darken($color-green, 10%);

$color-blue: rgb(51, 112, 178);
$color-blue-hover: darken($color-blue, 10%);

$color-red: rgb(255, 72, 97);
$color-red-hover: darken($color-red, 10%);

$color-dark-red: rgb(114, 28, 36);
$color-light-red: rgb(248, 142, 125);
$color-very-light-red: rgb(248, 215, 218);

$color-text: rgb(100, 100, 100);

$color-black: rgb(0, 0, 0);
$color-black-shadow: rgba(15, 15, 15, 0.502);
$color-gray: rgb(120, 121, 133);
$color-light-gray: rgb(216, 223, 233);
$color-dusty-white: rgb(221, 221, 221);
$color-white: rgb(255, 255, 255);

$color-additional: rgb(150, 150, 150);
$color-additional-hover: darken($color-additional, 10%);

$color-body: rgb(240, 240, 240);
// $color-body: rgb(228, 249, 224);

$color-border: rgba(51, 50, 70, 0.2);
$color-border-fieldset: rgba(68, 47, 47, 0.3)